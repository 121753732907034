import { RollbarService } from './../rollbar/rollbar.service';
import { Inject, Injectable } from '@angular/core';
import * as bowser from 'bowser';
import { forIn } from 'lodash';
import { RegularExpressionsUtility } from '../../utilities/regular-expressions/regular-expressions.utility';
import {
  BROWSER_HUMAN_NAME,
  BROWSER_VERSIONS_SUPPORTED,
  IBrowserVersionsSupportedConfig,
  BrowserUpgradeStatus,
  BrowserHumanName,
} from './../../constants/browser-versions-supported.constant';
import * as Rollbar from 'rollbar';
import { ModalsService } from '../../modals/modals.service';
import { take, tap } from 'rxjs/operators';
import { WindowRef } from 'projects/shared/services/window-ref/windowRef';
export type TBrowserActionType = 'SWITCH' | 'UPDATE' | null;
export interface IBrowserMsgConfig {
  action: TBrowserActionType;
  name: BrowserHumanName;
  minVersionReq: number;
  currentVersion: number;
}
@Injectable()
export class UserAgentService {
  readonly BROWSER_VERSIONS_SUPPORTED = BROWSER_VERSIONS_SUPPORTED;
  readonly BROWSER_HUMAN_NAME = BROWSER_HUMAN_NAME;
  private matchDecimalChars = RegularExpressionsUtility.matchDecimalChars;
  private matchComparisonOperatorChars = RegularExpressionsUtility.matchComparisonOperatorChars;

  constructor (
    @Inject(RollbarService) private rollbar: Rollbar,
    private modalsService: ModalsService,
    private windowRef: WindowRef,
  ) {}

  // to allow for easier mocking (CM).
  public get browser (): bowser.Parser.BrowserDetails {
    return bowser.getParser(this.windowRef.nativeWindow.navigator.userAgent).getBrowser();
  }

  public get currentBrowserVersion (): number | null {
    let version: string | number = this.browser.version;

    if (typeof version === 'string') {
      version = parseFloat(version);
    }

    // parsedFloat will return NaN if input is not a valid string that can be parsed to a float (CM).
    if (!version || isNaN(version)) {
      this.rollbar.debug(`UserAgentService#currentBrowserVersion has an invalid value: ${this.browser.version}`);

      return null;
    }

    return version;
  }

  public get currentBrowserName (): string | null {
    const { name } = this.browser;
    const formattedName = name && name.toLowerCase && name.toLowerCase();

    if (!formattedName) {
      this.rollbar.debug(`UserAgentService#currentBrowserName has no valid name: ${this.browser.name}`);

      return null;
    }

    return formattedName;
  }

  public getBrowserSupportStatus (): {
    name: BrowserHumanName;
    minVersionReq: number;
    currentVersion: number;
    status: string;
    } {
    const browserSupportConfigs: IBrowserVersionsSupportedConfig =
      this.BROWSER_VERSIONS_SUPPORTED[this.currentBrowserName] || this.BROWSER_VERSIONS_SUPPORTED.other;
    const name: BrowserHumanName = this.BROWSER_HUMAN_NAME[this.currentBrowserName] || this.BROWSER_HUMAN_NAME.other;
    const browserSupportStatus = { name, minVersionReq: null, status: null, currentVersion: this.currentBrowserVersion };

    forIn(browserSupportConfigs, (status, versionToCompare) => {
      if (versionToCompare === 'All') {
        browserSupportStatus.status = status;
        return false;
      }

      const statusForBrowserVersion = this.hasStatusForBrowserVersion(this.currentBrowserVersion, versionToCompare);

      if (statusForBrowserVersion) {
        browserSupportStatus.status = status;
        browserSupportStatus.minVersionReq = this.getVersionNumber(versionToCompare);

        return false;
      }
    });

    // Log info status for a few weeks to track the type of browsers that are generating not supported messages (CM).
    if (browserSupportStatus.minVersionReq) {
      const browserInfo = `name: ${this.currentBrowserName}, version: ${this.currentBrowserVersion}.`;
      this.rollbar.info(`User attempted to log in with unsupported brower: ${browserInfo}`, browserInfo);
    }

    return browserSupportStatus;
  }

  public hasStatusForBrowserVersion (currentBrowserVersion: number, versionToCompare: string): boolean {
    const operator = this.getVersionComparisonOperator(versionToCompare);
    const versionNumber = this.getVersionNumber(versionToCompare);

    switch (operator) {
      case '<': {
        return currentBrowserVersion < versionNumber;
      }
      case '>': {
        return currentBrowserVersion > versionNumber;
      }
      case '<=': {
        return currentBrowserVersion <= versionNumber;
      }
      case '>=': {
        return currentBrowserVersion >= versionNumber;
      }
      default: {
        this.rollbar.warning(`Invalid browser version operator: ${operator}`);
        return false;
      }
    }
  }

  public getVersionNumber (versionToCompare: string): number {
    const versionNumberMatches = this.matchDecimalChars(versionToCompare);
    const versionNumber = versionNumberMatches && versionNumberMatches.length && parseFloat(versionNumberMatches[0]);

    return versionNumber;
  }

  public getVersionComparisonOperator (versionToCompare: string): string {
    const operatorMatches = this.matchComparisonOperatorChars(versionToCompare);
    const operator = operatorMatches && operatorMatches.length && operatorMatches[0];

    return operator;
  }

  public showUnsupportedBrowserMessage (afterClosed?: () => void): void {
    const browserConfig = this.getBrowserMessageConfig();
    if (browserConfig) {
      this.modalsService.openUnsupportedBrowserModal({ title: 'Optimize your experience', config: browserConfig })
        .afterClosed()
        .pipe(
          tap(() => {
            if (afterClosed) afterClosed();
          }),
          take(1),
        ).subscribe();
    }
  };

  private getBrowserMessageConfig (): IBrowserMsgConfig {
    const { status, name, minVersionReq, currentVersion } = this.getBrowserSupportStatus();
    let config: IBrowserMsgConfig;
    if (status === BrowserUpgradeStatus.FullButNotOptimized || status === BrowserUpgradeStatus.MustSwitch) {
      config = { action: 'SWITCH', name, minVersionReq, currentVersion };
    } else if (status === BrowserUpgradeStatus.MustUpdateOrSwitch) {
      config = { action: 'UPDATE', name, minVersionReq, currentVersion };
    } else {
      config = null;
    }
    return config;
  }
}
