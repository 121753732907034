import { IFocus } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { ONE_YR_PRIOR_SUMMER_SCHOOL_YEAR_SHORT } from 'Src/ng2/shared/constants/current-school-year.constant';
import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';
import { IGroupData } from 'Src/ng2/shared/models/list-models';
import { GraphType } from 'Src/ng2/shared/models/viz-models';
import { District } from 'Src/ng2/shared/typings/interfaces/district.interface';
import { StudentOtherSchoolType } from 'Src/ng2/shared/typings/interfaces/student.interface';
import { absByMonthBarGraphCustomFormatter } from 'Src/ng2/shelter/profile/profile-panels/attendance-panel/attendance-panel.models';
import { INvRadialGraphData, NvRadialGraphDataType } from 'Src/nvps-libraries/design/nv-radial-graph/nv-radial-graph.interface';

interface ICreditsBarConfig {
  earnedColor: string;
  scheduledColor: string;
  tooltip: string;
  linePosition?: number;
}

const ALL_SCHOOL_TYPES = ['ES', 'MS', 'HS'];

const TASKS_ALL_COMPLETED_MESSAGE = 'All tasks completed!';
const TASKS_NOT_YET_CREATED_MESSAGE = 'No tasks created yet';
const NOTES_NOT_ADDED_YET_MESSAGE = 'No notes created yet';
const NO_SUCCESS_MENTOR_MESSAGE = 'Not assigned';
const COURSES_PASSING_RADIAL_GRAPH = {
  key: 'COURSES_PASSING_RADIAL_GRAPH',
  graphCustomFormat: data => {
    const {
      CURRENT_COURSES_WITH_MARKS_PASSING: currVal,
      CURRENT_COURSES_WITH_MARKS: withMarks,
      COUNT_CURRENT_COURSES: allCourses,
    } = data;
    const formattedCurrVal = currVal;
    const formattedMaxVal = allCourses === 0 ? null : withMarks;
    let noDataMessage;
    if (formattedMaxVal === null) noDataMessage = 'No data';
    if (formattedMaxVal === 0) noDataMessage = 'No marks';
    const graphData: INvRadialGraphData = {
      annotationText: 'Courses passing',
      currVal: formattedCurrVal,
      minVal: 0,
      maxVal: formattedMaxVal,
      noDataMessage,
      satisfactoryIndicator: ({ currVal, maxVal }) => currVal >= maxVal,
      satisfactoryIndicatorParams: {
        currVal: formattedCurrVal,
        maxVal: formattedMaxVal,
      },
      unsatisfactoryIndicator: ({ currVal, maxVal }) => currVal <= (maxVal / 2), // half or less than half of courses passing
      unsatisfactoryIndicatorParams: {
        currVal: formattedCurrVal,
        maxVal: formattedMaxVal,
      },
      dataType: NvRadialGraphDataType.COUNT,
    };
    return graphData;
  },
};

const COLORS = {
  GREEN: {
    dark: 'var(--color-green)',
    light: 'var(--color-green-light-1)',
  },
  RED: {
    dark: 'var(--color-red-dark-1)',
    light: 'var(--color-red-light-1)',
  },
  BLUE: {
    light: 'var(--color-blue-light-5)',
  },
  GREY: {
    light: 'var(--color-grey-light-8)',
  },
};

const getProgressBarStates = ({ STUDENT_YEAR, TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED, TOTAL_CREDS_EARNED, TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM, MAX_CREDIT_GAPS, isActive }, totalCredits) => {
  const commonStates = {
    EMPTY_STATE: STUDENT_YEAR <= 1 && TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED === 0,
    BEHIND: TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED < TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM,
    BEHIND_NO_GAPS: TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED >= TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM,
    ON_TRACK: TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED === TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM,
    AHEAD: TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED > TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM && TOTAL_CREDS_EARNED < totalCredits,
    CELEBRATION: TOTAL_CREDS_EARNED >= totalCredits,
  };
  if (isActive) {
    return {
      ...commonStates,
      EMPTY_STATE: commonStates.EMPTY_STATE && MAX_CREDIT_GAPS >= 0,
      BEHIND: commonStates.BEHIND && MAX_CREDIT_GAPS < 0,
      BEHIND_NO_GAPS: commonStates.BEHIND_NO_GAPS && MAX_CREDIT_GAPS < 0,
      ON_TRACK: commonStates.ON_TRACK && MAX_CREDIT_GAPS >= 0,
      AHEAD: commonStates.AHEAD && MAX_CREDIT_GAPS >= 0,
      CELEBRATION: commonStates.CELEBRATION && MAX_CREDIT_GAPS >= 0,
    };
  }
  return commonStates;
};

const getConfig = ({ isActive, celebration, behind, behindNoGaps, ahead, onTrack, linePosition, creditsEarned, creditsScheduled, totalCreditsNeeded, subjectsWithGaps }): ICreditsBarConfig => {
  const states = {
    celebration: {
      earnedColor: COLORS.GREEN.dark,
      scheduledColor: COLORS.GREEN.dark,
      tooltip: 'Student has earned all credits needed.',
    },
    behindNoGaps: {
      earnedColor: COLORS.RED.dark,
      scheduledColor: COLORS.RED.light,
      tooltip: isActive
        ? `According to your school’s credit metrics, the student is scheduled for the expected number of total credits but has scheduling gaps in <b>${subjectsWithGaps}</b>.`
        : '',
      linePosition,
    },
    behind: {
      earnedColor: COLORS.RED.dark,
      scheduledColor: COLORS.RED.light,
      tooltip: isActive
        ? `Expected to have ${totalCreditsNeeded} credits by end of the current term according to your school’s credit metrics. Scheduling gaps in <b>${subjectsWithGaps}</b>.`
        : '',
      linePosition,
    },
    ahead: {
      earnedColor: COLORS.GREEN.dark,
      scheduledColor: COLORS.GREEN.light,
      tooltip: `According to your school's credit metrics, the student is expected to have ${totalCreditsNeeded} credits by end of the current term and is scheduled to exceed this goal.`,
      linePosition,
    },
    onTrack: {
      earnedColor: COLORS.GREEN.dark,
      scheduledColor: COLORS.GREEN.light,
      tooltip: `According to your school's credit metrics, the student is expected to have ${totalCreditsNeeded} credits by end of the current term and is scheduled to meet this goal. Student earned ${creditsEarned} and is scheduled for ${creditsScheduled} credits.`,
      linePosition,
    },
    default: {
      earnedColor: COLORS.GREEN.dark,
      scheduledColor: COLORS.GREEN.light,
      tooltip: '',
    },
  };

  if (celebration) return states.celebration;
  if (behindNoGaps) return states.behindNoGaps;
  if (behind) return states.behind;
  if (ahead) return states.ahead;
  if (onTrack) return states.onTrack;

  return states.default;
};

const getCreditsEarned = (TOTAL_CREDS_EARNED, totalCredits) => {
  return Math.min((TOTAL_CREDS_EARNED || 0) / totalCredits, 1) * 100;
};

const getCreditsScheduled = (TOTAL_CREDS_EARNED, totalCredits, CREDITS_SCHEDULED) => {
  if (TOTAL_CREDS_EARNED + CREDITS_SCHEDULED > totalCredits) {
    return (totalCredits - TOTAL_CREDS_EARNED) / totalCredits * 100;
  }
  return Math.min((CREDITS_SCHEDULED || 0) / totalCredits, 1) * 100;
};

const regentsMapping = {
  ela: 'ELA',
  math: 'Math',
  plus1: 'Plus One',
  sci: 'Sci',
  ss: 'SS',
};

const formatList = (arr) => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return arr.join(' and ');

  return arr.slice(0, -1).sort().join(', ') + ', and ' + arr[arr.length - 1];
};

const getExpectedRegents = (subjects) => subjects?.map(subject => regentsMapping[subject] || subject);

const parseFulfilledRegents = (fulfilled) =>
  fulfilled ? fulfilled.split(',').map(subject => subject.trim()) : [];

const getRegentsProgressStates = (studentYear, validCompleted, behind, totalExpectedNow, totalCompleted, totalRegents) => ({
  emptyState: studentYear <= 1 && totalCompleted === 0 && behind === 0,
  celebrationState: totalCompleted === totalRegents && behind === 0,
  ahead: validCompleted >= totalExpectedNow && totalCompleted > totalExpectedNow,
  onTrack: totalCompleted === totalExpectedNow && behind === 0,
  behind: behind > 0,
});

const getRegentsTooltip = (states, expectedByNow, behindForGrad) => {
  if (states.celebrationState) return 'Student has fulfilled all Regents needed.';
  if (states.emptyState) return '';
  if (states.ahead) return `Expected to fulfill ${expectedByNow.length ? expectedByNow : 0} by now and has exceeded that goal.`;
  if (states.onTrack) return `Expected to fulfill ${expectedByNow} by now according to your school's Regents metrics and has met that goal.`;
  if (states.behind) return `Expected to fulfill ${expectedByNow} by now according to your school's Regents metrics. <b>Behind in ${behindForGrad}</b>.`;

  return '';
};

const getProgressSegments = (total, expectedByNow, validCompleted, totalCompleted, behind) => {
  if (total === 0) return [];
  const expectedCount = Math.min(expectedByNow, total);
  const validCompleteCount = Math.min(validCompleted, expectedCount);
  let behindCount = behind;
  let extraCompleted = Math.max(totalCompleted - validCompleted, 0);

  return Array.from({ length: total }, (_, index) => {
    if (index < validCompleteCount) {
      return { color: COLORS.GREEN.dark }; // Green (expected completion)
    } else if (behindCount > 0) {
      behindCount--;
      return { color: COLORS.RED.dark }; // Red (missing expected)
    } else if (extraCompleted > 0) {
      extraCompleted--;
      return { color: COLORS.GREEN.dark }; // Green (extra beyond expected)
    } else {
      return { color: COLORS.GREY.light }; // Grey (remaining)
    }
  });
};

export const mapCellValueToColorCategory = {
  Gaps: 'red-light-1 dark-red-text',
  Behind: 'red-light-1 dark-red-text',
  'Failing 1+': 'red-light-1 dark-red-text',
  'Below 80%': 'red-light-1 dark-red-text',
  'On Track': 'green-light-1 dark-green-text',
  'Passing all': 'green-light-1 dark-green-text',
  'Above 80%': 'green-light-1 dark-green-text',
  'All set': 'green-light-1 dark-green-text',
  'No marks': 'grey-light-8 dark-grey-text',
  'No data': 'grey-light-8 dark-grey-text',
  'No attendance': 'grey-light-8 dark-grey-text',
};

export const SNAPSHOT_CONFIG = {
  CORE4: {
    header: 'Core 4',
    headerLeftIcon: 'core4-blue',
    headerIcon: 'info-small-hover',
    headerTooltip: 'Core 4 is our framework for monitoring student progress to graduation. Quickly identify needs based on recent attendance, course grades, credit accumulation, and Regents',
    columns: [
      // TODO: add back projection when ready
      // {
      //   key: 'CORE_4_GRAD_PROJECTION',
      //   human: '4-Year graduation projection: ',
      //   tooltip: "A student's likelihood to graduate on time, based on the actual graduation outcomes of similar students in prior cohorts.",
      //   path: 'CORE_4_GRAD_PROJECTION',
      //   studentTypes: ['HS'],
      //   contextPartnerTypes: ['school'],
      //   otherSchoolTypes: [],
      //   districts: [District.NYC],
      //   customFormat: (data) => {
      //     const projection = data?.split(': ') || EM_DASH;
      //     return projection.length > 1 ? projection.slice(1).join(': ') : projection;
      //   },
      //   style: (data) => mapCellValueToColorCategory[data] || null,
      // },
      {
        key: 'CORE_4_ATTENDANCE_ALERT',
        human: 'Attendance',
        tooltip: 'Based on the percent of days present in the last 20 school days.',
        icon: () => 'attendance-blue',
        path: ['CORE_4_ATTENDANCE_ALERT', 'CORE_4_ATTENDANCE_ALERT_CELL_TOOLTIP'],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.MINEOLA, District.UNIONDALE],
        customFormat: (data) => {
          const { CORE_4_ATTENDANCE_ALERT, CORE_4_ATTENDANCE_ALERT_CELL_TOOLTIP } = data;
          return { alert: CORE_4_ATTENDANCE_ALERT, tooltip: CORE_4_ATTENDANCE_ALERT_CELL_TOOLTIP || null };
        },
        style: (data) => mapCellValueToColorCategory[data.CORE_4_ATTENDANCE_ALERT] || null,
      },
      {
        key: 'CORE_4_CREDITS_ALERT',
        human: 'Credits',
        tooltip: "Based on credits earned compared to your school's credit metrics. A student is behind if they have less credits earned than expected overall, or in any requirement area.",
        icon: () => 'credits-blue',
        path: ['CORE_4_CREDITS_ALERT', 'CORE_4_CREDITS_ALERT_CELL_TOOLTIP'],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.MINEOLA, District.UNIONDALE],
        customFormat: (data) => {
          const { CORE_4_CREDITS_ALERT, CORE_4_CREDITS_ALERT_CELL_TOOLTIP } = data;
          return { alert: CORE_4_CREDITS_ALERT, tooltip: CORE_4_CREDITS_ALERT_CELL_TOOLTIP || null };
        },
        style: (data) => mapCellValueToColorCategory[data.CORE_4_CREDITS_ALERT] || null,
      },
      {
        key: 'CORE_4_GRADES_ALERT',
        human: 'Grades',
        tooltip: 'Based on courses failing in the latest MP in subjects where the student has not met graduation requirements.',
        icon: () => 'grades-blue',
        path: ['CORE_4_GRADES_ALERT', 'CORE_4_GRADES_ALERT_CELL_TOOLTIP'],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.MINEOLA, District.UNIONDALE],
        customFormat: (data) => {
          const { CORE_4_GRADES_ALERT, CORE_4_GRADES_ALERT_CELL_TOOLTIP } = data;
          return { alert: CORE_4_GRADES_ALERT, tooltip: CORE_4_GRADES_ALERT_CELL_TOOLTIP || null };
        },
        style: (data) => mapCellValueToColorCategory[data.CORE_4_GRADES_ALERT] || null,
      },
      {
        key: 'CORE_4_REGENTS_ALERT',
        human: 'Regents',
        tooltip: "Based on Regents fulfilled for minimum graduation compared to your school's Regents metrics. A student is behind if they have fulfilled fewer Regents than expected by now.",
        icon: () => 'regents-blue',
        path: ['CORE_4_REGENTS_ALERT', 'CORE_4_REGENTS_ALERT_CELL_TOOLTIP', 'PLANNED_DIPLOMA_TYPE'],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.MINEOLA, District.UNIONDALE],
        customFormat: (data) => {
          const { CORE_4_REGENTS_ALERT, CORE_4_REGENTS_ALERT_CELL_TOOLTIP } = data;
          return { alert: CORE_4_REGENTS_ALERT, tooltip: CORE_4_REGENTS_ALERT_CELL_TOOLTIP || null };
        },
        style: (data) => mapCellValueToColorCategory[data.CORE_4_REGENTS_ALERT] || null,
      },
    ],
  },
  ACTIVITY: {
    header: 'Tasks and Notes',
    columns: [
      { // LANSING ONLY
        key: 'TASKS_TO_DO',
        human: 'Tasks to do',
        path: ['TASKS_TO_DO', 'TASKS_ALL_COMPLETED', 'TASKS_NOT_YET_CREATED'],
        studentTypes: ['MS', 'ES', 'HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
        districts: [District.LANSING],
        customFormat: data => {
          const { TASKS_TO_DO, TASKS_ALL_COMPLETED, TASKS_NOT_YET_CREATED } = data;
          let text = null;
          let icon = null;
          let style = null;
          if (TASKS_TO_DO === 0 && TASKS_ALL_COMPLETED) {
            text = TASKS_ALL_COMPLETED_MESSAGE;
            icon = 'celebration-green';
            style = 'satisfactory';
          } else if (TASKS_TO_DO === 0 && TASKS_NOT_YET_CREATED) {
            text = TASKS_NOT_YET_CREATED_MESSAGE;
            style = 'empty-state';
          }
          return {
            message: {
              text,
              icon,
              style,
            },
            count: TASKS_TO_DO,
          };
        },
      },
      { // LANSING ONLY
        key: 'COUNT_OF_ACTIVE_NOTES',
        human: 'Notes',
        path: 'COUNT_OF_ACTIVE_NOTES',
        studentTypes: ['MS', 'ES', 'HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.LANSING],
        customFormat: COUNT_OF_ACTIVE_NOTES => {
          let text = null;
          const icon = null;
          let style = null;
          if (COUNT_OF_ACTIVE_NOTES === 0) {
            text = NOTES_NOT_ADDED_YET_MESSAGE;
            style = 'empty-state';
          }
          return {
            message: {
              text,
              icon,
              style,
            },
            count: COUNT_OF_ACTIVE_NOTES,
          };
        },
      },
      { // NYC NON-HS
        key: 'TASKS_TO_DO',
        human: 'Tasks to do',
        path: ['TASKS_TO_DO', 'TASKS_ALL_COMPLETED', 'TASKS_NOT_YET_CREATED'],
        studentTypes: ['MS', 'ES'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
        districts: [District.NYC, District.MINEOLA],
        customFormat: data => {
          const { TASKS_TO_DO, TASKS_ALL_COMPLETED, TASKS_NOT_YET_CREATED } = data;
          let text = null;
          let icon = null;
          let style = null;
          if (TASKS_TO_DO === 0 && TASKS_ALL_COMPLETED) {
            text = TASKS_ALL_COMPLETED_MESSAGE;
            icon = 'celebration-green';
            style = 'satisfactory';
          } else if (TASKS_TO_DO === 0 && TASKS_NOT_YET_CREATED) {
            text = TASKS_NOT_YET_CREATED_MESSAGE;
            style = 'empty-state';
          }
          return {
            message: {
              text,
              icon,
              style,
            },
            count: TASKS_TO_DO,
          };
        },
      },
      { // NYC NON-HS
        key: 'COUNT_OF_ACTIVE_NOTES',
        human: 'Notes',
        path: 'COUNT_OF_ACTIVE_NOTES',
        studentTypes: ['MS', 'ES'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.MINEOLA],
        customFormat: COUNT_OF_ACTIVE_NOTES => {
          let text = null;
          const icon = null;
          let style = null;
          if (COUNT_OF_ACTIVE_NOTES === 0) {
            text = NOTES_NOT_ADDED_YET_MESSAGE;
            style = 'empty-state';
          }
          return {
            message: {
              text,
              icon,
              style,
            },
            count: COUNT_OF_ACTIVE_NOTES,
          };
        },
      },
    ],
  },
  NOTES: {
    header: 'Notes',
    columns: [
      {
        key: 'COUNT_OF_ACTIVE_NOTES',
        human: 'Notes',
        path: 'COUNT_OF_ACTIVE_NOTES_SHELTER',
        studentTypes: ALL_SCHOOL_TYPES,
        contextPartnerTypes: ['shelter'],
        otherSchoolTypes: [],
        districts: [District.NYC],
        customFormat: COUNT_OF_ACTIVE_NOTES => {
          let text = null;
          const icon = null;
          let style = null;
          if (COUNT_OF_ACTIVE_NOTES === 0) {
            text = NOTES_NOT_ADDED_YET_MESSAGE;
            style = 'shelter-note-empty-state';
          }
          return {
            message: {
              text,
              icon,
              style,
            },
            count: COUNT_OF_ACTIVE_NOTES,
          };
        },
      },
    ],
  },
  SUCCESS_MENTORING: {
    header: 'Success Mentoring',
    columns: [
      {
        key: 'SHELTER_CURRENT_SUCCESS_MENTORING_NAME',
        human: 'Success Mentor',
        path: 'SHELTER_CURRENT_SUCCESS_MENTORING_NAME',
        studentTypes: ['HS'],
        contextPartnerTypes: ['shelter'],
        otherSchoolTypes: [],
        districts: [District.NYC],
        customFormat: data => (data === null ? NO_SUCCESS_MENTOR_MESSAGE : data),
      },
    ],
  },
  ACADEMICS: {
    header: 'Academics',
    graph: COURSES_PASSING_RADIAL_GRAPH,
    columns: [
      { // LANSING
        key: 'COURSES_PASSING_RADIAL_GRAPH',
        human: 'Courses passing',
        path: ['CURRENT_COURSES_WITH_MARKS_PASSING', 'COUNT_CURRENT_COURSES', 'CURRENT_COURSES_WITH_MARKS'],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.LANSING],
        customFormat: data => data,
        isGraph: true,
      },
      { // LANSING
        key: 'CURRENT_GPA',
        human: 'Transcript GPA',
        path: 'CURRENT_GPA',
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.LANSING],
        customFormat: data => (data === null ? EM_DASH : data),
      },
      {
        key: 'COURSES_PASSING_RADIAL_GRAPH',
        human: 'Courses passing',
        path: ['CURRENT_COURSES_WITH_MARKS_PASSING', 'COUNT_CURRENT_COURSES', 'CURRENT_COURSES_WITH_MARKS'],
        studentTypes: ['MS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => data,
        isGraph: true,
      },
      {
        key: 'LATEST_ELA_STATE_EXAM_PR',
        human: 'ELA State Exam PR',
        path: 'LATEST_ELA_STATE_EXAM_PR',
        studentTypes: ['MS', 'ES'],
        otherSchoolTypes: [],
        contextPartnerTypes: ['school'],
        districts: [District.NYC, District.MINEOLA],
        customFormat: data => data,
      },
      {
        key: 'LATEST_MATH_STATE_EXAM_PR',
        human: 'Math State Exam PR',
        path: 'LATEST_MATH_STATE_EXAM_PR',
        studentTypes: ['MS', 'ES'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.MINEOLA],
        customFormat: data => data,
      },
      {
        key: 'MS_GPA',
        human: 'GPA',
        path: 'MS_GPA',
        studentTypes: ['MS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => (data === null ? EM_DASH : data),
      },
      {
        key: 'ACTIVE_ACADEMIC_SUPPORT_COUNT',
        human: 'Academic supports',
        path: 'ACTIVE_ACADEMIC_SUPPORT_COUNT',
        studentTypes: ['MS', 'ES'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => (data === null ? 0 : data),
      },
    ],
  },
  GRADES: {
    header: 'Grades',
    studentTypes: ['HS'],
    graph: COURSES_PASSING_RADIAL_GRAPH,
    columns: [
      {
        key: 'COURSES_PASSING_RADIAL_GRAPH',
        human: 'Courses passing',
        path: ['CURRENT_COURSES_WITH_MARKS_PASSING', 'COUNT_CURRENT_COURSES', 'CURRENT_COURSES_WITH_MARKS'],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.MINEOLA, District.UNIONDALE],
        customFormat: data => data,
        isGraph: true,
      },
      {
        key: 'CURRENT_GPA',
        human: 'Transcript GPA',
        path: 'CURRENT_GPA',
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.MINEOLA, District.UNIONDALE],
        customFormat: data => (data === null ? EM_DASH : data),
      },
    ],
  },
  ATTENDANCE: {
    header: 'Attendance',
    graph: {
      key: 'ABSENCES_LAST_5_MONTHS',
      graphCustomFormat: absByMonthBarGraphCustomFormatter,
    },
    columns: [
      {
        key: 'CURR_YTD_ATT',
        human: 'YTD Attendance',
        summerName: `Attendance ${ONE_YR_PRIOR_SUMMER_SCHOOL_YEAR_SHORT}`,
        path: 'CURR_YTD_ATT',
        studentTypes: ALL_SCHOOL_TYPES,
        otherSchoolTypes: [],
        districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => (data === null ? EM_DASH : `${data}%`),
        contextPartnerTypes: ['school', 'shelter'],
      },
      {
        key: 'DOE_RISK_GROUP_ATT',
        human: 'Risk group',
        path: 'DOE_RISK_GROUP_ATT',
        studentTypes: ALL_SCHOOL_TYPES,
        otherSchoolTypes: [],
        districts: [District.NYC],
        customFormat: data => (data ?? 'No DOE risk group'),
        contextPartnerTypes: ['school', 'shelter'],
      },
      {
        key: 'ATT_SUPPORTS',
        human: 'Attendance supports',
        path: 'ATT_SUPPORTS',
        studentTypes: ALL_SCHOOL_TYPES,
        otherSchoolTypes: [],
        districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => (data === null ? 0 : data),
        contextPartnerTypes: ['school', 'shelter'],
      },
      {
        key: 'ABSENCES_LAST_5_MONTHS',
        human: 'Absences last 5 months',
        path: 'ABSENCES_LAST_5_MONTHS',
        studentTypes: ALL_SCHOOL_TYPES,
        otherSchoolTypes: [],
        districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => data,
        isGraph: true,
        contextPartnerTypes: ['school', 'shelter'],
      },
    ],
  },
  CREDITS: {
    header: 'Credits',
    studentTypes: ['ES', 'MS', 'HS'],
    columns: [
      {
        key: 'CREDITS_PROGRESS_BAR',
        human: 'Credits earned',
        path: ['TOTAL_CREDS_EARNED', 'CREDITS_SCHEDULED', 'TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED', 'TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM', 'SUBJECTS_WITH_CREDIT_GAPS', 'MAX_CREDIT_GAPS', 'TOTAL_CREDITS_REQUIRED_TO_GRAD', 'STUDENT_YEAR', 'STATUS'],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.MINEOLA, District.UNIONDALE],
        customFormat: (data) => {
          const { TOTAL_CREDS_EARNED, CREDITS_SCHEDULED, TOTAL_CREDITS_REQUIRED_TO_GRAD, TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM, SUBJECTS_WITH_CREDIT_GAPS, TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED, STATUS, STUDENT_YEAR, MAX_CREDIT_GAPS } = data;

          const totalCredits = TOTAL_CREDITS_REQUIRED_TO_GRAD;

          const isActive = STATUS === 'A';

          const progressStates = getProgressBarStates({ STUDENT_YEAR, TOTAL_44REQ_CREDITS_EARNED_PLUS_SCHEDULED, TOTAL_CREDS_EARNED, TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM, MAX_CREDIT_GAPS, isActive }, totalCredits);

          const linePosition = (TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM / totalCredits) * 100;
          const config = getConfig({
            isActive,
            celebration: progressStates.CELEBRATION,
            behind: progressStates.BEHIND,
            behindNoGaps: progressStates.BEHIND_NO_GAPS,
            ahead: progressStates.AHEAD,
            onTrack: progressStates.ON_TRACK,
            linePosition,
            creditsEarned: TOTAL_CREDS_EARNED,
            creditsScheduled: CREDITS_SCHEDULED,
            totalCreditsNeeded: TOTAL_44REQ_CREDITS_NEEDED_BY_NEXT_TERM,
            subjectsWithGaps: formatList(SUBJECTS_WITH_CREDIT_GAPS),
          });

          return {
            barData: [
              {
                key: 'CREDITS_EARNED',
                value: getCreditsEarned(TOTAL_CREDS_EARNED, totalCredits),
                color: TOTAL_CREDS_EARNED === 0 ? config.scheduledColor : config.earnedColor,
              },
              {
                key: 'CREDITS_SCHEDULED',
                value: getCreditsScheduled(TOTAL_CREDS_EARNED, totalCredits, CREDITS_SCHEDULED),
                color: config.scheduledColor,
              },
            ],
            states: {
              red: progressStates.BEHIND || progressStates.BEHIND_NO_GAPS,
              emptyState: progressStates.EMPTY_STATE,
              celebrationState: progressStates.CELEBRATION,
            },
            credsEarned: TOTAL_CREDS_EARNED,
            totalCredits,
            tooltip: config.tooltip,
            linePosition: config.linePosition + 6,
          };
        },
      },
      {
        key: 'CREDITS_NO_PROGRESS_BAR',
        human: 'Credits earned',
        path: 'TOTAL_CREDS_EARNED',
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.LANSING],
        customFormat: data => (data === null ? EM_DASH : data),
      },
      {
        key: 'CREDITS_NO_PROGRESS_BAR',
        human: 'Status',
        path: ['CREDITS_BEHIND', 'CREDITS_BANDS'],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.LANSING],
        customFormat: (data) => {
          const { CREDITS_BEHIND, CREDITS_BANDS } = data;
          if (CREDITS_BANDS === null) {
            return EM_DASH;
          }
          if (CREDITS_BANDS?.toLowerCase() === 'on track') {
            return CREDITS_BANDS;
          }
          return CREDITS_BEHIND > 1 ? `${CREDITS_BEHIND} credits behind` : `${CREDITS_BEHIND} credit behind`;
        },
        icon: data => {
          const { CREDITS_BANDS } = data;
          if (CREDITS_BANDS === null) {
            return null;
          }
          return CREDITS_BANDS?.toLowerCase() === 'on track' ? 'check-circle-small-green' : 'warning-small';
        },
        style: data => {
          const { CREDITS_BANDS } = data;
          if (CREDITS_BANDS === null) {
            return null;
          }
          return CREDITS_BANDS?.toLowerCase() === 'on track' ? 'satisfactory' : 'unsatisfactory';
        },
      },
    ],
  },
  GRADUATION: {
    header: 'Graduation',
    studentTypes: ['ES', 'MS'],
    columns: [
      {
        key: 'PLANNED_DIPLOMA_TYPE',
        human: 'Grad plan',
        path: 'PLANNED_DIPLOMA_TYPE',
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => (data === null ? EM_DASH : data),
      },
      {
        key: 'ON_TRACK_FOR_GRAD_STATUS',
        human: 'Status',
        path: 'ON_TRACK_FOR_GRAD_STATUS',
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => (data === null ? EM_DASH : data),
        icon: data => {
          if (data === null) {
            return null;
          }
          return data?.toLowerCase() === 'on track' ? 'check-circle-small-green' : 'warning-small';
        },
        style: data => {
          if (data === null) {
            return null;
          }
          return data?.toLowerCase() === 'on track' ? 'satisfactory' : 'unsatisfactory';
        },
      },
    ],
  },
  REGENTS: {
    header: 'Regents',
    studentTypes: ['ES', 'MS', 'HS'],
    columns: [
      {
        key: 'REGENTS_PROGRESS_BAR',
        human: ({ MINIMUM_DIPLOMA_TYPE }) => {
          return `Fulfilled for ${MINIMUM_DIPLOMA_TYPE} diploma`;
        },
        path: [
          'COUNT_OF_REGENTS_FULFILLED_FOR_GRADUATION',
          'COUNT_OF_REGENTS_BEHIND_FOR_GRADUATION',
          'REGENTS_BEHIND_FOR_GRADUATION',
          'REGENTS_FULFILLED_FOR_GRAD',
          'TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD',
          'COUNT_OF_TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD',
          'STUDENT_YEAR',
          'MINIMUM_DIPLOMA_TYPE',
        ],
        studentTypes: ['HS'],
        contextPartnerTypes: ['school'],
        otherSchoolTypes: [],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE, District.MINEOLA],
        customFormat: data => {
          const {
            COUNT_OF_REGENTS_FULFILLED_FOR_GRADUATION,
            COUNT_OF_REGENTS_BEHIND_FOR_GRADUATION,
            REGENTS_BEHIND_FOR_GRADUATION,
            REGENTS_FULFILLED_FOR_GRAD,
            TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD,
            COUNT_OF_TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD,
            STUDENT_YEAR,
          } = data;

          const totalRegents = 5;

          const expectedByNowForGrad = getExpectedRegents(TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD) || [];
          const formattedExpectedByNow = formatList(expectedByNowForGrad);
          const behindForGrad = formatList(REGENTS_BEHIND_FOR_GRADUATION);

          const fulfilledArray = parseFulfilledRegents(REGENTS_FULFILLED_FOR_GRAD);
          const validFulfilledCount =
            fulfilledArray.length === 1 && fulfilledArray[0] === ''
              ? 0
              : fulfilledArray.filter(subject => formattedExpectedByNow.includes(subject)).length;

          const states = getRegentsProgressStates(
            STUDENT_YEAR,
            validFulfilledCount,
            COUNT_OF_REGENTS_BEHIND_FOR_GRADUATION,
            COUNT_OF_TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD,
            COUNT_OF_REGENTS_FULFILLED_FOR_GRADUATION,
            totalRegents,
          );

          const tooltip = getRegentsTooltip(states, formattedExpectedByNow, behindForGrad);

          const segmentColors = getProgressSegments(totalRegents, COUNT_OF_TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD, validFulfilledCount, COUNT_OF_REGENTS_FULFILLED_FOR_GRADUATION, COUNT_OF_REGENTS_BEHIND_FOR_GRADUATION);

          const hasVerticalLine = COUNT_OF_TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD > 0 && !states.celebrationState;
          const verticalLine = hasVerticalLine ? COUNT_OF_TOTAL_REGENTS_EXPECTED_TO_FULFILL_BY_NOW_FOR_GRAD : null;

          return {
            states,
            completed: COUNT_OF_REGENTS_FULFILLED_FOR_GRADUATION || 0,
            behind: COUNT_OF_REGENTS_BEHIND_FOR_GRADUATION || 0,
            totalRegents,
            verticalLine,
            tooltip,
            segmentColors,
          };
        },
      },
    ],
  },
};
