import { LocalStorageService } from './../../shared/services/web-storage/local-storage/local-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { WindowRef } from 'projects/shared/services/window-ref/windowRef';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { HelpDeskService } from '../../shared/services/help-desk/help-desk.service';
import { PortalConfig } from '../../shared/services/portal-config';
import { ISchool } from '../../shared/typings/interfaces/school.interface';
import { getSchool } from '../../store';
import { getCurrentUser } from './../../store/selectors/current-user-selectors';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { IPartner, PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { PORTAL_TYPES, TPortalLocation } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { District, TDistricts } from 'Src/ng2/shared/typings/interfaces/district.interface';
import { SideNavConfigService } from './side-nav.config.service';
import { combineLatest } from 'rxjs';
import { SessionStorageService } from 'Src/ng2/shared/services/web-storage/session-storage/session-storage.service';
import { convertAddressToTitleCase } from 'Src/ng2/shared/helpers/data-format/data-format.helper';
import { SharedEventTrackers } from 'Src/ng2/shared/services/mixpanel/event-trackers/shared-tracking.service';
import { PrevStateService } from 'Src/ng2/shared/services/prev-state/prev-state.service';

export interface ISidenav {
  [key: string]: ISidebarItem[]
}

/* istanbul ignore next */
@Component({
  selector: 'nvps-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavNgComponent implements OnInit {
  // portal switcher props
  public selectedView: TPortalLocation;
  public district: TDistricts;
  public contextPartnerType: TValidPartnerTypes;
  public contextPartnerId: string;
  public currentUser: IUser;
  public portalSwitcherLabel: string;

  // config + component props
  public schoolId: string;
  public shelterId: string;
  public sidenav: ISidenav;
  public activeUrl: string;
  public selectedSidebarItem: string;
  public openSidebarItems: string[];
  private publicConfig: PortalConfig['publicConfig'];
  public subLocationId: string | null; // Shelter Portal

  constructor (
    private helpDeskService: HelpDeskService,
    private portalConfig: PortalConfig,
    private router: Router,
    private store: Store<any>,
    private windowRef: WindowRef,
    private urlPathService: UrlPathService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private sideNavConfigService: SideNavConfigService,
    private sessionStorageService: SessionStorageService,
    private sharedEventsTrackers: SharedEventTrackers,
    private prevStateService: PrevStateService,
  ) {
    this.publicConfig = this.portalConfig.publicConfig;
  }

  ngOnInit () {
    this.activeUrl = this.getActiveUrl(this.router.routerState.snapshot.url);
    this.contextPartnerType = this.route.snapshot.data.contextPartnerType || PartnerTypes.SCHOOL;
    this.openSidebarItems = this.getLocalSidebarOpenItems(this.contextPartnerType);
    combineLatest([
      this.store.select(getCurrentUser).pipe(take(1)),
      this.store.select(getSchool).pipe(take(1)),
    ])
      .pipe(
        tap(([currentUser, school]) => {
          this.currentUser = currentUser;
          this.setContextProps(this.route, { school, contextPartnerType: this.contextPartnerType });
          this.sidenav = this.sideNavConfigService.getSideNav({
            school,
            contextPartnerType: this.contextPartnerType,
            contextPartnerId: this.contextPartnerId,
            district: this.district,
            selectedView: this.selectedView,
            user: this.currentUser,
            route: this.route,
          });
          this.updateSelectedItem([...this.sidenav.contentArea, ...this.sidenav.tools], { activeUrl: this.activeUrl });
        }),
      ).subscribe();
  }

  setContextProps (route: ActivatedRoute, opts: { school: ISchool, contextPartnerType: TValidPartnerTypes }): void {
    const { school, contextPartnerType } = opts;
    const { data, params, queryParams } = route.snapshot;
    switch (contextPartnerType) {
      case PartnerTypes.SHELTER:
        this.shelterId = params.shelterId;
        this.selectedView = PORTAL_TYPES.SHELTER;
        this.contextPartnerId = this.shelterId;
        this.district = District.NYC;
        this.portalSwitcherLabel = this.getShelterLabel(data.shelter, route);
        this.subLocationId = queryParams.subLocationId;
        break;
      case PartnerTypes.SCHOOL:
      default:
        this.schoolId = params.schoolId;
        this.selectedView = PORTAL_TYPES.SCHOOL;
        this.contextPartnerId = this.schoolId;
        this.district = school.district;
        this.portalSwitcherLabel = school.nickName;
        this.subLocationId = null;
        break;
    }
  }

  private getShelterLabel (shelter: IPartner, route: ActivatedRoute): string {
    const subLocationId = route.snapshot.queryParams.subLocationId ?? '';
    let label = shelter.partnerName;
    if (subLocationId.length > 0) {
      this.sessionStorageService.setItem('subLocationId', subLocationId);
      const allSubLocations = shelter.subLocations;
      if (allSubLocations?.length > 0) {
        const currentSubLocation = allSubLocations.find((subLocation) => subLocation.addressId === subLocationId);
        if (currentSubLocation.address.length > 0) {
          label = `${label} - ${convertAddressToTitleCase(currentSubLocation.address)}`;
        }
      }
    }
    return label;
  }

  public goExternal (name: string): void {
    switch (name) {
      case 'Get Help': {
        this.helpDeskService.showHelp();
        break;
      }
      default:
        return null;
    }
  }

  public onNavItemSelect (menuConfigs: ISidebarItem[], { selectedItemKey }: { selectedItemKey?: string }) {
    const navItem = menuConfigs.find(item => item.key === selectedItemKey);
    if (selectedItemKey === 'DEFAULT_SDC_GRID') this.prevStateService.setNavigationSource('sideNav');
    if (navItem?.children) {
      const isSelectedOptionOpen = this.openSidebarItems.includes(selectedItemKey);
      if (isSelectedOptionOpen) this.openSidebarItems = this.openSidebarItems.filter(key => key !== selectedItemKey);
      else this.openSidebarItems.push(selectedItemKey);
      this.setLocalSidebarOpenItems(this.openSidebarItems, this.contextPartnerType);
    }
    this.trackLefthandNavEvent(selectedItemKey, this.selectedView);
    this.updateSelectedItem(menuConfigs, { selectedItemKey });
  }

  setLocalSidebarOpenItems (value: any, contextPartnerType: TValidPartnerTypes): void {
    switch (contextPartnerType) {
      case PartnerTypes.SHELTER:
        this.localStorage.setItem('shelterSideNavOpenState', value);
        break;
      case PartnerTypes.SCHOOL:
      default:
        this.localStorage.setItem('schoolSideNavOpenState', value);
        break;
    }
  }

  getLocalSidebarOpenItems (contextPartnerType: TValidPartnerTypes): string[] {
    switch (contextPartnerType) {
      case PartnerTypes.SHELTER:
        // eslint-disable-next-line no-case-declarations
        const shelterSideNavOpenState = this.localStorage.getItem('shelterSideNavOpenState');
        // Since ATTENDANCE is a default open item for shelters, we need to add it to the open items if it's not already there
        return shelterSideNavOpenState ? [...shelterSideNavOpenState, 'ATTENDANCE'] : ['ATTENDANCE'];
      case PartnerTypes.SCHOOL:
      default:
        return this.localStorage.getItem('schoolSideNavOpenState') || [];
    }
  }

  public updateSelectedItem (menuConfigs: ISidebarItem[], { selectedItemKey, activeUrl }: { selectedItemKey?: string, activeUrl?: string }): ISidebarItem {
    if (selectedItemKey) { // find item by key
      const navItem = menuConfigs.find(navItem => {
        if (navItem.key === selectedItemKey) return true;
        if (navItem.children) return this.updateSelectedItem(navItem.children, { selectedItemKey });
        return false;
      });
      if (navItem && !navItem?.children) {
        this.selectedSidebarItem = selectedItemKey;
        return navItem;
      }
    } else { // find item by url
      let exactMatch: ISidebarItem;
      const partialMatches: ISidebarItem[] = [];
      for (const navItem of menuConfigs) {
        if (navItem.children) this.updateSelectedItem(navItem.children, { activeUrl });
        const isExactMatch = activeUrl === `${this.getActiveUrl(navItem.url)}`;
        const partialMatch = activeUrl.includes(`${this.getActiveUrl(navItem.url)}`);
        if (isExactMatch) {
          exactMatch = navItem;
          this.selectedSidebarItem = navItem.key;
          break;
        } else if (partialMatch) {
          partialMatches.push(navItem);
        }
      }

      // exit case 1: an exact match is found
      if (exactMatch) return exactMatch;
      // exit case 2: there's only one partial match
      else if (partialMatches.length === 1) {
        this.selectedSidebarItem = partialMatches[0].key;
        return partialMatches[0];
      } else if (partialMatches.length > 1) {
        // exit case 3: find the closest partial match
        const activeUrlArr = activeUrl.split('/');
        const selectedItem = this.compareUrl(activeUrlArr, partialMatches, 0);
        if (selectedItem) this.selectedSidebarItem = selectedItem.key;
        return selectedItem;
      }
    }
  }

  private compareUrl (activeUrl: string[], navItemMatches: ISidebarItem[], index: number): ISidebarItem {
    if (!activeUrl[index]) return null;
    if (navItemMatches.length === 1) return navItemMatches[0];

    const matches = navItemMatches.filter((item: ISidebarItem) => {
      const urlArr = this.getActiveUrl(item.url).split('/');
      return urlArr[index] === activeUrl[index];
    });

    if (matches.length === 0 && navItemMatches.length === 1) return navItemMatches[0];

    index += 1;
    return this.compareUrl(activeUrl, matches, index);
  }

  getActiveUrl (url: string): string {
    if (!url) return;
    const [urlWithoutParams] = url.split('?');
    const remainingPath = urlWithoutParams.split('/').slice(3);
    const activeUrl = remainingPath.join('/');
    return activeUrl;
  }

  public navigateToHomepage ({ contextPartnerType, contextPartnerId }): void {
    const url = this.urlPathService.computeDistrictUrlPath(`/${contextPartnerType}/${contextPartnerId}/lists`);
    this.router.navigate([url]);
  }

  private trackLefthandNavEvent (selectedItemKey: string, selectedView: TPortalLocation): void {
    const navItem = selectedItemKey;
    const portal = selectedView;
    this.sharedEventsTrackers.trackLefthandNavEvent({ navItem, portal });
  }
}
