import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'request-consent',
  templateUrl: 'request-consent.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RequestConsentComponent {
  public modalText = 'Click <strong>Request</strong> to send an email to the family\'s primary guardian with a DocuSign link to complete the "Every Child and Family is Known" program consent form.';

  public getPayload () {
    return {};
  }
}
