import { LocalStorageService } from './shared/services/web-storage/local-storage/local-storage.service';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { identity, merge } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { unsubscribeComponent } from './shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { ApiService } from './shared/services/api-service/api-service';
import { HelpDeskService } from './shared/services/help-desk/help-desk.service';
import { ImNotification } from './shared/services/im-models/im-notification.service';
import { PortalConfig } from './shared/services/portal-config';
import { WindowRef } from '../../projects/shared/services/window-ref/windowRef';
import { INotification } from './shared/typings/interfaces/notification.interface';
import { getCurrentUser, getCurrentUserLoadedStatus } from './store/selectors/current-user-selectors';
import { GoogleAnalyticsTrackerService } from '../../projects/shared/services/google-analytics/google-analytics-tracker.service';
import { UserAgentService } from './shared/services/user-agent.service.ts/user-agent.service';
import { UserInactivity } from './shared/services/user-inactivity/user-inactivity.service';

@Component({
  selector: 'data-portal',
  // still keeping v4 just in case there are any leftovers
  template: `
    <div class="v4">
      <notifications
        *ngIf="(notificationsForUser$ | async) as notifications"
        [notifications]="notifications"
        [currentUser]="this.getCurrentUser$() | async"
      ></notifications>
      <div class="environment-indicator" [ngClass]="env"></div>

      <router-outlet (activate)="onActivate()" (deactivate)="onDeactive()"></router-outlet>
    </div>
  `,
  styleUrls: ['./styles/main.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class AppComponent implements OnInit {
  public env: string = '';
  public envConfig: { [key: string]: boolean } = {};
  public currentEnvClass: string;
  public notificationsForUser$: Observable<Array<INotification>>;
  public showUiView: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private currentUser$: Observable<IUser>;
  public newSkinModeIsOn$: Observable<boolean>;

  constructor (
    private api: ApiService,
    private helpDeskService: HelpDeskService,
    private imNotification: ImNotification,
    private imUser: ImUser,
    private portalConfig: PortalConfig,
    private store: Store<any>,
    private mixpanelService: MixpanelService,
    private localStorageService: LocalStorageService,
    private googleAnalyticsTrackerService: GoogleAnalyticsTrackerService,
    private userAgentService: UserAgentService,
    private userInactivity: UserInactivity,
  ) {
    // initiate the user session inactivity timer
    this.userInactivity.start();

    // initiate google analytics
    if (this.portalConfig.publicConfig.GOOG_ANALTICS4_ID) {
      this.googleAnalyticsTrackerService.handleRouteEvents();
    }

    if (
      this.portalConfig.publicConfig.DISPLAY_NV_ENV !== '' &&
      this.portalConfig.publicConfig.DISPLAY_ENV_PATH !== 'prod'
    ) {
      this.env = this.portalConfig.publicConfig.DISPLAY_NV_ENV.toLowerCase(); // sets the env indicator class
      /* eslint no-undef: 0 */
      const favicon: HTMLLinkElement = document.querySelector('link[rel="icon"]');
      favicon.href = `/assets/img/favicon-${this.env}.svg`;
      const title = document.querySelector('title');
      title.innerText = `${this.portalConfig.publicConfig.DISPLAY_NV_ENV} - Portal by New Visions`;
    }
  }

  ngOnInit (): void {
    this.helpDeskService.startHelpDeskService();
    this.notificationsForUser$ = this.getNotificationsForUser$();
    this.setMixpanelUser();
    this.showUnsupportedBrowserMessage();
  }

  public onActivate (): void {
    this.showUiView.next(false);
  }

  public onDeactive (): void {
    this.showUiView.next(true);
  }

  private getNotificationsForUser$ (): Observable<Array<INotification>> {
    return combineLatest([this.getCurrentUser$(), this.getNotifications$()]).pipe(
      filter(([currentUser, notifications]) => !!currentUser && !!notifications),
      // Once both of these emit we know a user in authenticated and set the user session
      tap(([currentUser]) => this.imUser.setCurrentUserSession(currentUser)),
      switchMap(([user, notifications]) => of(notifications)),
    );
  }

  public getCurrentUser$ (): Observable<IUser> {
    this.currentUser$ = this.store.pipe(
      select(getCurrentUserLoadedStatus),
      filter(identity),
      switchMap(() => this.store.pipe(select(getCurrentUser))),
    );
    return this.currentUser$;
  }

  private getNotifications$ (): Observable<Array<INotification>> {
    return this.store.pipe(
      select(getCurrentUserLoadedStatus),
      filter(identity),
      switchMap(() => this.api.getNotifications({ params: { } })),
      map(({ data }: { data: Array<INotification> }) => data),
    );
  }

  private setMixpanelUser () {
    this.getCurrentUser$().subscribe(user => {
      if (user) {
        const { userAnalyticsHash } = user;
        const lastLogin = this.localStorageService.getItem('lastLogin');
        this.mixpanelService.updateUser(userAnalyticsHash, {
          'Last Login': lastLogin,
        });
      }
    });
  }

  private showUnsupportedBrowserMessage () {
    this.getCurrentUser$().pipe(
      tap(() => {
        const firstSession = this.localStorageService.getItem('firstSession');
        if (firstSession) {
          const afterClosed = () => this.localStorageService.removeItem('firstSession');
          this.userAgentService.showUnsupportedBrowserMessage(afterClosed);
        }
      }),
      take(1),
    ).subscribe();
  };
}
