import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBrowserMsgConfig } from '../../services/user-agent.service.ts/user-agent.service';
import { WindowRef } from 'projects/shared/services/window-ref/windowRef';
import { EventFormatterService } from '../../services/mixpanel/event-formatter.service';
import { MixpanelService } from '../../services/mixpanel/mixpanel.service';
import { BrowserHumanName } from '../../constants/browser-versions-supported.constant';

export interface IUnsupportedBrowserModalData {
  title: string;
  config: IBrowserMsgConfig;
}

export enum UnsupportedBrowserClickEventOption {
  /* eslint-disable no-unused-vars */
  PROCEED = 'Proceed',
  CHROME_SWITCH = 'Chrome switch',
  CHROME_UPDATE = 'Chrome update',
}

export const DOWNLOAD_CHROME_LINK = 'https://www.google.com/chrome/';
export const UPDATE_CHROME_LINK = 'https://www.google.com/chrome/update/';

@Component({
  templateUrl: './unsupported-browser-modal.component.html',
  styleUrls: ['./unsupported-browser-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class UnsupportedBrowserModalComponent implements OnInit {
  title: string;
  message: string;

  buttons: {
    acknowledgeWarningText: string;
    takeActionText: string;
  };

  constructor (
    private windowRef: WindowRef,
    public dialogRef: MatDialogRef<UnsupportedBrowserModalComponent>,
    private eventFormatterService: EventFormatterService,
    private mixpanelService: MixpanelService,
    @Inject(MAT_DIALOG_DATA) public data: IUnsupportedBrowserModalData,
  ) {}

  ngOnInit (): void {
    this.title = this.data.title;
    const { message, buttons } = this.getMessageAndButtons(this.data.config);
    this.message = message;
    this.buttons = buttons;
  }

  public onAcknowledgeWarning (): void {
    this.trackUnsupportedBrowserClickEvent(UnsupportedBrowserClickEventOption.PROCEED);
    this.dialogRef.close();
  }

  public onTakeAction (): void {
    const { action, name: browserName } = this.data.config;
    if (action === 'UPDATE' && browserName === BrowserHumanName.CHROME) {
      this.trackUnsupportedBrowserClickEvent(UnsupportedBrowserClickEventOption.CHROME_UPDATE);
      this.windowRef.nativeWindow.open(UPDATE_CHROME_LINK, '_blank');
    } else {
      this.trackUnsupportedBrowserClickEvent(UnsupportedBrowserClickEventOption.CHROME_SWITCH);
      this.windowRef.nativeWindow.open(DOWNLOAD_CHROME_LINK, '_blank');
    }
    this.dialogRef.close();
  };

  private getMessageAndButtons (config: IBrowserMsgConfig): { message: string, buttons: { acknowledgeWarningText: string, takeActionText: string } } {
    let message: string;
    const buttons = {
      acknowledgeWarningText: 'Proceed to the Portal',
      takeActionText: null,
    };

    const { action, name: browserName, currentVersion } = config;
    if (action === 'UPDATE' && browserName === BrowserHumanName.CHROME) {
      message = `The Portal works better when using the latest version of Google Chrome. If you continue with Chrome Version ${currentVersion}, you might experience some disruptions or limited functionality.`;
      buttons.takeActionText = 'Update Chrome';
    } else {
      message = `The Portal works better when using the latest version of Google Chrome. If you continue with ${browserName}, you might experience some disruptions or limited functionality.`;
      buttons.takeActionText = 'Download Chrome';
    };
    return { message, buttons };
  };

  private trackUnsupportedBrowserClickEvent (option: UnsupportedBrowserClickEventOption): void {
    const event = this.eventFormatterService.getUnsupportedBrowserClickEvent(option);
    this.mixpanelService.trackEvents([event]);
  }
}
