import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api-service/api-service';
import { ICategoryContainer, ITile, SUBCONTAINER_CATEGORIES } from './interfaces/dashboard.interfaces';
import { groupBy, toArray } from 'lodash';
import {
  IContainerTilesSpecialArrangement,
  TileVisualState,
  TTileVisualState,
} from 'Src/nvps-libraries/design/nv-category-container/nv-category-container.component';
import { INetDashTile } from 'Src/ng2/shared/typings/interfaces/network-tile.interface';
import { TEmptyStateData } from 'projects/shared/components/empty-state/empty-state.component';

@Injectable()
export class TilesService {
  constructor (private apiService: ApiService) {}

  public getTilesConfig$ (schoolId: string, contextPartnerType: string, contextPartnerId: string): Observable<any> {
    const query = `{
      TilesConfig(schoolId: "${schoolId}", contextPartnerType: "${contextPartnerType}", contextPartnerId: "${contextPartnerId}") {
        tiles {
          key
          tileName
          description
          categoryContainer
          tileType
          populationFilter
          fociDataType
          numeratorKey
          denominatorKey
          tileOrder
          category
          categoryKey
          subcategory
          defaultHomepageSection
          categoryOrder
          categoryIcon {
            name
            position
            tooltipData
          }
          contentAreaKey
          contentAreaTooltip
          focusKey
          showDenominator
          showDashForZeroPop
          tileCustomEmptyState {
            iconName
            imageName
            mainText
            subText
            buttonLabel
            buttonType
          }
          tileMeta {
            key
          }
          actionTextOptions {
            actionStart
            actionReview
            actionComplete
          }
          navigation
          gridFilterKey
          graphQlKeyFocus
          defaultGraphQlKeyFilter
          defaultGraphQlKeyGrouping
          iconName
          iconHeader
          graph {
            colorPalette
            human
            graphQlKeyPopulationFilter
            groupingKey
            graphQlKeyVizStackedGrouping
            graphType
            vizDateStamp
            graphPosition
          }
        }
        filters {
          key
          human
          category_key
          option_order
          is_default
        }
      }
    }`;

    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getMonitorConfig(payload).pipe(
      take(1),
      map(res => {
        const {
          data: { TilesConfig },
        } = res;
        return TilesConfig;
      }),
      catchError(err => {
        if (err) {
          return throwError(EMPTY);
        }
      }),
    );
  }

  public groupContainersBySection (tileData) {
    const groupedCategories = toArray(groupBy(tileData, 'defaultHomepageSection'));
    return groupedCategories.reduce(function (accum, section, idx) {
      accum.push({ section: section[0], cats: section });
      return accum;
    }, []);
  }

  public groupTilesByCategory (sections) {
    let groupedByCategory = sections.map(section => {
      const groupedTiles = toArray(groupBy(section.cats, 'categoryOrder'));
      return groupedTiles.reduce((accum, group) => {
        const { category, catFilter, categoryKey } = group[0];
        const formattedCategory = category.replace(/_/g, ' ');
        group = this.sortTilesByTileOrder(group);
        accum.push({ cat: formattedCategory, tiles: group, categoryKey, catFilter: catFilter || null });
        return accum;
      }, []);
    });
    const categoryMap = new Map();
    groupedByCategory.forEach(section => {
      section.forEach(category => {
        categoryMap.set(category.cat, true);
      });
    });
    groupedByCategory = groupedByCategory.reduce((accum, section) => {
      if (
        section.length > 1 &&
        section[1].tiles[0].categoryContainer === 'horizontal' &&
        section[1].tiles.length >= 4
      ) {
        section.forEach(cat => {
          accum.push([cat]);
        });
      } else {
        accum.push(section);
      }
      return accum;
    }, []);
    groupedByCategory = this.groupPathways(groupedByCategory);
    return groupedByCategory;
  }

  groupPathways (groupedCategories) {
    const regroupedCategories = groupedCategories.reduce((accum, group) => {
      if (group[0].cat !== 'Pathways enrollment') {
        accum.push(group);
      }
      if (group[0].cat === 'Pathways enrollment') {
        const pathways = group[0].tiles.reduce((pathwaysAccum, tile) => {
          if (pathwaysAccum.includes(tile.subcategory)) return pathwaysAccum;
          pathwaysAccum.push(tile.subcategory);
          return pathwaysAccum;
        }, []);
        pathways.forEach(pathway => {
          const pathwayTiles = group[0].tiles.filter(tile => tile.subcategory === pathway);
          group.push({ cat: '', tiles: pathwayTiles });
        });
        group.shift();
        group[0].cat = 'Pathways enrollment';
        accum.push(group);
      }

      return accum;
    }, []);

    regroupedCategories.forEach(group => {
      group.sort((subcatA, subcatB) => {
        const subcatAOrder = subcatA.tiles[0].subcategoryOrder;
        const subcatBOrder = subcatB.tiles[0].subcategoryOrder;
        return subcatAOrder - subcatBOrder;
      });
    });
    return regroupedCategories;
  }

  // Sorting Functions
  sortTilesByTileOrder (tileData: ITile[]): ITile[] {
    tileData.sort((a, b) => {
      return a.tileOrder - b.tileOrder;
    });
    return tileData;
  }

  public sortGroupsByCategoryOrder (tileData) {
    tileData.forEach(section => {
      section.sort((a, b) => {
        const catA = a.tiles[0];
        const catB = b.tiles[0];
        return catA.categoryOrder - catB.categoryOrder;
      });
    });
    return tileData;
  }

  public processGroups (tileData, schoolType = null) {
    return tileData.map(section => {
      return section.map(category => {
        return {
          ...category,
          categoryColor: this._getCategoryColor(category),
          categoryIcon: this._getCategoryIcon(category),
          containerType: this.getContainerType(category),
          contentAreaTooltip: this.getContentAreaTooltip(category),
          needsSubcontainer: this._getNeedsSubcontainer(category),
          selectedCategoryFilter: category.selectedCategoryFilter,
          subcategoryPosition: this.getSubcategoryPosition(category),
          subtitle: this.getContainerSubtitle(category, schoolType),
        };
      });
    });
  }

  private getSubcategoryPosition (category: any): string {
    let subcategoryPosition: string;
    if (category.tiles.length > 0) {
      subcategoryPosition = category.tiles[0].subcategoryPosition;
    }
    return subcategoryPosition;
  }

  private getContentAreaTooltip (category) {
    let tooltip = '';
    if (category.tiles.length > 0) {
      tooltip = category.tiles[0].contentAreaTooltip || '';
    }
    return tooltip;
  }

  private getContainerSubtitle (container, schoolType = null): string {
    const nonTransferHS = ['K to 12', '6 to 12', 'Small HS', 'Large HS'];
    if (container.tiles.length > 0) {
      if (container.cat === 'Postsecondary' && schoolType === 'Transfer') {
        return 'Active senior criteria students';
      } else if (container.cat === 'Postsecondary' && nonTransferHS.includes(schoolType)) {
        return 'Active 12th grade students';
      } else if (container.tiles[0].categoryContainer === 'split-tiles') {
        return '';
      } else if (container.tiles[0].subcategoryPosition) {
        return null;
      } else {
        return container.tiles[0].subcategory ?? '';
      }
    }
  }

  private getContainerType (container) {
    if (container.tiles.length > 0) {
      const containerKey = container.tiles[0].categoryContainer;
      return containerKey || '';
    }
  }

  private categoryRatio = {
    '3:1': 'threeToOne',
    '3:2': 'threeToTwo',
    '3:3': 'threeToThree',
    '4:1': 'fourToOne',
    '4:2': 'fourToTwo',
    '4:3': 'fourToThree',
    '5:1': 'fiveToOne',
    '5:2': 'fiveToTwo',
    '5:3': 'fiveToThree',
  };

  public setBasicInfoClass (sectionData: ICategoryContainer[]): string {
    if (sectionData?.length >= 2) {
      const cat1 = sectionData[0];
      const cat1length = cat1.tiles.length;
      const cat2 = sectionData[1];
      const cat2length = cat2.tiles.length;
      const catRatioLookup = `${cat1length}:${cat2length}`;
      return this.categoryRatio[catRatioLookup];
    }
  }

  public getSpecialArrangement (tiles: INetDashTile[] | ITile[]): IContainerTilesSpecialArrangement {
    const noViz = tiles.filter(({ tileType }) => tileType === 'viz')?.length === 0;
    if (noViz) {
      return {
        horizontal: 'horizontal-fixed-four-columns',
      };
    }
    return null;
  }

  private _getCategoryIcon (category) {
    if (category.tiles.length > 0) {
      return category.tiles[0].categoryIcon || null;
    }
  }

  private _getCategoryColor (category) {
    if (category.tiles.length > 0) {
      return category.tiles[0].categoryColor || null;
    }
  }

  private _getNeedsSubcontainer (category) {
    if (category.tiles.length > 0) return SUBCONTAINER_CATEGORIES.includes(category.tiles[0].categoryContainer);
    return false;
  }

  public getUserNotifications$ (): Observable<any> {
    return this.apiService.getUserNotifications$();
  }

  public updateUserNotification$ (notificationId): Observable<any> {
    return this.apiService.updateUserNotificationAcknowledgement$(notificationId);
  }

  public validateZeroPop (tile: ITile): boolean {
    return tile.value === null || (tile.showDashForZeroPop && tile.denominator === 0);
  }

  public validateCustomEmptyState (tile: ITile): boolean {
    return !!tile.tileCustomEmptyState && (tile.value === null || tile.denominator === 0);
  }

  public getVisualState (opts: {
    showDashForZeroPop: boolean;
    tileCustomEmptyState: TEmptyStateData;
  }): TTileVisualState {
    const { showDashForZeroPop, tileCustomEmptyState } = opts;
    let visualState;
    if (showDashForZeroPop === false && !tileCustomEmptyState) {
      visualState = TileVisualState.REGULAR;
    }
    if (showDashForZeroPop === true) {
      visualState = TileVisualState.DASH;
    }
    if (tileCustomEmptyState) {
      visualState = TileVisualState.EMPTY_STATE;
    }
    return visualState;
  }
}
