// take a look at https://github.com/lancedikson/bowser#browser-flags
// for potential browser type values (CM).
export type TBrowserTypes = 'chrome' | 'safari' | 'firefox' | 'microsoft edge' | 'msie' | 'other';

export enum BrowserUpgradeStatus {
  FullButNotOptimized = 'FullButNotOptimized',
  MustUpdateOrSwitch = 'MustUpdateOrSwitch',
  MustSwitch = 'MustSwitch',
}

export interface IBrowserVersionsSupportedConfig {
  [key: string]: BrowserUpgradeStatus;
}

export enum BrowserHumanName {
  /* eslint-disable no-unused-vars */
  CHROME = 'Chrome',
  SAFARI = 'Safari',
  FIREFOX = 'Firefox',
  MS_EDGE = 'Microsoft Edge',
  MS_IE = 'IE',
  OTHER ='your browser',
}

type TBrowserVersionsSupported = { [key in TBrowserTypes]: IBrowserVersionsSupportedConfig };

// browser versions should include one of the following:
// <, <=, >, >=, or 'All' (CM)
export const BROWSER_VERSIONS_SUPPORTED: TBrowserVersionsSupported = {
  chrome: {
    '<64': BrowserUpgradeStatus.MustUpdateOrSwitch,
  },
  safari: {
    '>=12': BrowserUpgradeStatus.FullButNotOptimized,
    '<12': BrowserUpgradeStatus.MustUpdateOrSwitch,
  },
  firefox: {
    '>=62': BrowserUpgradeStatus.FullButNotOptimized,
    '<62': BrowserUpgradeStatus.MustUpdateOrSwitch,
  },
  'microsoft edge': {
    '>=79': BrowserUpgradeStatus.FullButNotOptimized,
    '<79': BrowserUpgradeStatus.MustUpdateOrSwitch,
  },
  msie: {
    All: BrowserUpgradeStatus.MustSwitch,
  },
  other: {
    All: BrowserUpgradeStatus.MustSwitch,
  },
};

export const BROWSER_HUMAN_NAME = {
  chrome: 'Chrome',
  safari: 'Safari',
  firefor: 'Firefox',
  'microsoft edge': 'Microsoft Edge',
  msie: 'IE',
  other: 'your browser', // for other browsers
};
