<div class="unsupported-browser-dialog">
  <div class="dialog-title">
    <nv-icon [name]="'warning-large'" class="alert-icon"></nv-icon>
    <h2>{{ title }}</h2>
  </div>

  <div class="dialog-body">
    <mat-dialog-content>
      <ng-container>
        <p class="body">{{ message }}</p> 
      </ng-container>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions class="dialog-actions">
    <nv-button class="btn" type="secondary" (click)="onTakeAction()">{{ buttons.takeActionText }}</nv-button>
    <nv-button class="btn" (click)="onAcknowledgeWarning()">{{ buttons.acknowledgeWarningText }}</nv-button>
  </mat-dialog-actions>
</div>
